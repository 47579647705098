import logo from '@assetsApp/logo-ticsocial.svg';
import './ticsocialWatermark.scss';

const TicsocialWatermark = () => {
  return (
    <div className="ticsocial_watermark">
      <div className="d-flex align-items-center gap-2">
        <img src={logo} alt="logo ticsocial" />
      </div>
    </div>
  );
};

export default TicsocialWatermark;
