import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import AppLogo from '@assetsApp/app_logo.svg';
import { BasicModal, Button, CustomIcon } from '@componentsApp/common/index';
import { ROUTES } from '@constantsApp/index';
import { useLocalStorage } from '@hooksApp/index';
import { tnl } from '@i18nApp/i18n';
import { updateUserStorage } from '@routesApp/PrivateRoute/PrivateRouteRequest';
import { sidebarItemsRender } from '@utilsApp/index';
import './sidebar.scss';

const Sidebar = ({ collapse }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useLocalStorage();
  const [showModal, setShowModal] = useState('none');

  const releaseChanges = [
    'Se soluciona el error donde la nueva novedad no se visualizaba',
    'Se fixea la variable de NO PBS',
    'Se soluciona un error en el apartado de los infusores con las horas.',
    'Se remueve el queryparam view: PROGRAMATION',
    'Se añaden las razones de central de mezclas y el tab de central de mezclas',
    'Se envia el queryparam view: PROGRAMATION',
    'Ahora desde el tab de jefe de programacion se puede actualizar el numero de telefono del paciente',
    'El dia del protocolo en modal QF se le da una solucion, pendiente validar en test'
  ];

  const availableModals = {
    deployModal: (
      <BasicModal
        title="buttons.releases"
        body={
          <>
            <p>
              <b>Cambios en la versión</b>
            </p>
            <ul>
              {releaseChanges.map((change, index) => (
                <li key={`change_${index}`}>{change}</li>
              ))}
            </ul>
            <p className="fw-bold">
              {`Front-${tnl('Develop')}: `}
              <span className="fw-normal">1.5.39</span>
            </p>
          </>
        }
        onHide={() => setShowModal('none')}
      />
    )
  };

  return (
    <>
      {showModal !== 'none' && availableModals[showModal]}
      <div
        className={`sidebar ${collapse ? 'sidebar_hidden' : 'sidebar_visible'}`}
      >
        <div className="sidebar_content">
          <NavLink to={ROUTES.PLATFORM} className="sidebar_logo_container">
            <Image src={AppLogo} alt="app_logo" className="sidebar_logo" />
          </NavLink>
          {/* <p className="sidebar_domain mb-3">{userData.domain_name}</p> */}
          {userData.is_superuser && (
            <Button
              variant="link"
              iconName="releases"
              title="buttons.releases"
              onClick={() => setShowModal('deployModal')}
            />
          )}
          {sidebarItemsRender(userData.sidebarData, location)}
          <div className="sidebar_userOptions">
            <CustomIcon name="user" color="white" size="LG" />
            <div className="sidebar_userLogged">
              <div className="d-flex gap-2 align-items-center">
                {userData?.first_name ?? ' - '}
                <div
                  title={tnl('buttons.update_user')}
                  onClick={updateUserStorage}
                >
                  <CustomIcon
                    name="reload"
                    color="white"
                    size="XS"
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <Button
                variant="link"
                iconName="logout"
                text="buttons.logout"
                onClick={() => {
                  localStorage.clear();
                  navigate(ROUTES.LOGIN);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
